<template>
    <div>
        <div class="flex flex-row space-x-6 w-full">
            <div class="input-container w-full">
                <label>Courriel</label>
                <div class="c__input">
                    <input
                        type="text"
                        name="email"
                        v-model="schoolUser.email" />
                </div>
                <p
                    class="error"
                    v-if="errors.email">
                    {{ errors.email }}
                </p>
            </div>
            <div class="input-container w-full">
                <label>Prénom</label>
                <div class="c__input">
                    <input
                        type="text"
                        name="firstName"
                        v-model="schoolUser.firstName" />
                </div>
                <p
                    class="error"
                    v-if="errors.firstName">
                    {{ errors.firstName }}
                </p>
            </div>

            <div class="input-container w-full">
                <label>Nom</label>
                <div class="c__input">
                    <input
                        type="text"
                        name="lastName"
                        v-model="schoolUser.lastName" />
                </div>
                <p
                    class="error"
                    v-if="errors.lastName">
                    {{ errors.lastName }}
                </p>
            </div>
        </div>

        <div class="grid grid-cols-2 gap-6">
            <item-select
                :disabled="!!schoolUser.id"
                hide-empty
                select
                v-model="schoolUser.role"
                :data="schoolUserRoles"
                label="Rôle"
                :error="errors.role"
                :value-key="null"
                :label-key="(item) => item" />
            <item-select
                hide-empty
                select
                v-model="schoolUser.lang"
                :data="Object.values(AVAILABLE_LANGS)"
                label="Langue"
                :error="errors.lang"
                :value-key="null"
                :label-key="(item) => item" />
        </div>

        <div class="grid grid-cols-2 gap-6">
            <items-select
                @search="onSchoolSearch"
                v-model="schoolUser.schools"
                :data="schools"
                label="École"
                label-key="name"
                value-key="id"
                :error="errors.schools" />

            <items-select
                v-if="schoolUser.role === USER_ROLES.TEACHER"
                @search="onClassroomSearch"
                v-model="schoolUser.classrooms"
                :error="errors.classrooms"
                label="Classes (selon l'école)"
                :data="searchedClassrooms"
                label-key="title"
                value-key="id" />
        </div>
        <template v-if="schoolUser.id && schoolUser.role === USER_ROLES.TEACHER">
            <h4 class="mt-4">Forfait:</h4>
            <div
                class="mt-2 space-y-2"
                v-if="schoolUser.license">
                <div class="">
                    <p>Commencer le:</p>
                    <p class="text-sm">
                        {{ moment(schoolUser.license.startedAt).utc().local().format("LL") }}
                    </p>
                </div>
                <div class="">
                    <p>Fini le:</p>
                    <p class="text-sm">
                        {{ moment(schoolUser.license.finishedAt).utc().local().format("LL") }}
                    </p>
                </div>
                <button
                    class="btn--outline my-4"
                    @click.prevent="$emit('cancelSubscription')">
                    Annuler le forfait
                </button>
            </div>
            <div
                class="mt-2"
                v-else>
                <p>Aucun forfait</p>
                <button
                    class="btn my-4"
                    @click.prevent="$emit('giveSubscription')">
                    Donner un forfait
                </button>
            </div>
        </template>
    </div>
</template>

<script>
import ItemsSelect from "components/forms/ItemsSelect"
import ajax from "config/ajax"
import ItemSelect from "components/forms/ItemSelect"
import { AVAILABLE_LANGS, USER_ROLES, WORD_GROUP_CATEGORIES } from "@globals/constants"

export default {
    name: "school-user-form",
    emits: ["cancelSubscription", "giveSubscription"],
    components: {
        ItemSelect,
        ItemsSelect
    },
    props: {
        schoolUser: {
            type: Object,
            required: true
        },
        errors: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            USER_ROLES,
            AVAILABLE_LANGS,
            WORD_GROUP_CATEGORIES,

            schoolUserRoles: [],
            schools: [],
            searchedClassrooms: []
        }
    },
    created() {
        this.schoolUserRoles = [USER_ROLES.TEACHER, USER_ROLES.SCHOOL_MANAGER]
    },
    methods: {
        onSchoolSearch(searchValue) {
            ajax.get(`/schools/search`, {
                params: {
                    search: searchValue
                }
            }).then((res) => {
                this.schools = res.schools
            })
        },
        onClassroomSearch(searchValue) {
            ajax.get(`/classrooms/search`, {
                params: {
                    search: searchValue,
                    ...(this.schoolUser.schoolId
                        ? {
                              schoolId: this.schoolUser.schoolId
                          }
                        : {}),
                    ...(this.schoolUser.teacherId
                        ? {
                              teacherId: this.schoolUser.teacherId
                          }
                        : {})
                }
            }).then((res) => {
                this.searchedClassrooms = res.classrooms
            })
        }
    }
}
</script>
